import React from 'react';
import {Button, Card, DatePicker, Form, Input, InputNumber, Select, Switch} from "antd";
import ClientField from "@common/Forms/Fields/ClientField";
import UmbrellaField from "@common/Forms/Fields/UmbrellaField";
import {connect} from "react-redux";
import {AccountType} from "../../gql/graphql";

const CandidateForm = (props: {
    loading: boolean,
    onSubmit: any,
    formRef: any,
    reset?: any,
    defaultValue?: any,
    currency: string[],
    country: string[]
}) => {
    const {onSubmit, formRef, loading} = props;
    const [form] = Form.useForm();
    const [refresh, setRefresh] = React.useState(false);
    return (
        <Form onFinish={onSubmit} layout="vertical" ref={formRef} form={form}>
            <Form.Item label="Name" required rules={[{required: true}]} name={'name'}>
                <Input placeholder="Name"/>
            </Form.Item>
            <Form.Item label="Personal Email" name={'personalEmail'} rules={[{type: 'email'}]}>
                <Input placeholder="Personal Email" type={'email'}/>
            </Form.Item>
            <Form.Item label="Contact" name={'contact'}>
                <Input placeholder="Contact"/>
            </Form.Item>
            <ClientField defaultValue={{client: props.defaultValue?.client, clientPOC: props.defaultValue?.clientPOC}}
                         form={form}/>
            <UmbrellaField
                defaultValue={{umbrella: props.defaultValue?.umbrella, umbrellaPOC: props.defaultValue?.umbrellaPOC}}
                form={form}/>
            <Form.Item label="Start Date" name={'startDate'} required rules={[{required: true}]}>
                <DatePicker/>
            </Form.Item>
            <Form.Item label={"Account Type"} name={'accountType'}>
                <Select
                    options={Object.keys(AccountType).map((item) => ({
                        value: item,
                        key: item,
                        label: item,
                        title: item
                    }))}
                />
            </Form.Item>
            <Form.Item label={"Currency"} name={'currency'}>
                <Select
                    options={props.currency.map((item) => ({value: item, key: item, label: item, title: item}))}
                />
            </Form.Item>
            <Form.Item label={"Country"} name={'country'}>
                <Select
                    options={props.country.map((item) => ({value: item, key: item, label: item, title: item}))}
                />
            </Form.Item>
            <Card title="Candidate Rate" className={'mb-5 w-full'}>
                <Form.Item label={"Per day rate"} name={"perDay"} valuePropName="checked" layout={"horizontal"}>
                    <Switch onChange={() => setRefresh(!refresh)}/>
                </Form.Item>
                <Form.Item label={'Rate'} name={['candidateRate', 'rate']} required rules={[{required: true}]}>
                    <InputNumber placeholder={'Rate'} className={'w-full'}
                                 addonAfter={form.getFieldValue("perDay") ? "$ / Day" : "$ / Hr"}/>
                </Form.Item>
                <Form.Item label={'OT Rate'} name={['candidateRate', 'otRate']} required rules={[{required: true}]}>
                    <InputNumber placeholder={'OT Rate'} className={'w-full'} addonAfter={"$ / Hr"}/>
                </Form.Item>
            </Card>

            <Card title="Client Rate" className={'mb-5 w-full'}>
                <Form.Item label={"Per day rate"} name={"perDay"} valuePropName="checked" layout={"horizontal"}>
                    <Switch onChange={() => setRefresh(!refresh)}/>
                </Form.Item>
                <Form.Item label={'Rate'} name={['clientRate', 'rate']} required rules={[{required: true}]}>
                    <InputNumber placeholder={'Rate'} className={'w-full'}
                                 addonAfter={form.getFieldValue("perDay") ? "$ / Day" : "$ / Hr"}/>
                </Form.Item>
                <Form.Item label={'OT Rate'} name={['clientRate', 'otRate']} required rules={[{required: true}]}>
                    <InputNumber placeholder={'OT Rate'} className={'w-full'} addonAfter={"$ / Hr"}/>
                </Form.Item>
            </Card>

            <div className={'w-full flex justify-end gap-5'}>
                <Button type={'primary'} loading={loading} htmlType={'submit'}>
                    Save
                </Button>
                <Button htmlType={props.reset ? "button" : 'reset'} onClick={props.reset}
                        loading={loading}>Reset</Button>
            </div>
        </Form>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currency: state.config.currency,
        country: state.config.country,
    }
}

export default connect(mapStateToProps)(CandidateForm);