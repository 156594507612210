import React from 'react';
import {Button, Card, Form, Input} from "antd";
import {connect} from "react-redux";

const ClientForm = (props: { loading: boolean, onSubmit: any, formRef: any, reset?: any, token: string }) => {
    const {onSubmit, formRef, loading} = props;
    const [form] = Form.useForm();

    return (
        <Form onFinish={onSubmit} layout="vertical" form={form} ref={formRef} onChange={(e) => console.log(e)}>
            <Form.Item label="Client Name" required name={'companyName'} rules={[{required: true}]}>
                <Input placeholder="Client Name"/>
            </Form.Item>
            <Card title="POC" className={'mb-5 w-full'}>
                <Form.Item label="Name" name={['poc','name']} required rules={[{required: true}]}>
                    <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item label="Email" name={['poc','email']} rules={[{type: 'email'}]}>
                    <Input placeholder="Email"/>
                </Form.Item>
                <Form.Item label="Phone" name={['poc','phone']}>
                    <Input placeholder="Phone"/>
                </Form.Item>
            </Card>
            <div className={'w-full flex justify-end gap-5'}>
                <Button type={'primary'} loading={loading} htmlType={'submit'}>
                    Save
                </Button>
                <Button htmlType={props.reset ? "button" : 'reset'} onClick={props.reset}
                        loading={loading}>Reset</Button>
            </div>
        </Form>
    );
};

const mapStateToProps = (state: any) => {
    return {
        token: state.user.tokens?.accessToken,
    }
}

export default connect(mapStateToProps)(ClientForm);