import {gql} from "@apollo/client";

export const CREATE_CANDIDATE = gql`
    mutation CreateCandidate($data: CandidateInput!) {
        addCandidate(data: $data)
    }
`

export const EDIT_CANDIDATE = gql`
    mutation UpdateCandidate($data: CandidateInput!, $id: String!) {
        updateCandidate(data: $data, id: $id)
    }
`

export const GET_CANDIDATE = gql`
    query GetCandidate($id: ObjectID) {
        candidate(id: $id) {
            name
            personalEmail
            contact
            clientRate {
                otRate
                rate
            }
            candidateRate {
                otRate
                rate
            }
            startDate
            client {
                id
                companyName
                poc{
                    id
                    name
                }
            }
            clientPOC {
                id
                name
                email
                phone
            }
            umbrellaPOC {
                id
                name
                email
                phone
            }
            umbrella {
                id
                companyName
                poc{
                    id
                    name
                }
            }
            currency
            country
            accountType
            perDay
            status
            stage
        }
    }
`

export const GET_CANDIDATES = gql`
    query QueryCandidate($pageNum: Int, $pageSize: Int) {
        candidates(pageNum: $pageNum, pageSize: $pageSize) {
            results {
                id
                name
                personalEmail
                contact
                client {
                    companyName
                }
                umbrella {
                    companyName
                }
            }
            total
        }
    }
`

export const UPDATE_CANDIDATE_STATUS = gql`
    mutation updateStatus($id: String) {
        updateStatus(id: $id)
    }
`