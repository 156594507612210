import React from 'react';
import {Button, Descriptions, Drawer} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import POC from "@common/POC";
import {ADD_CLIENTPOC, UPDATE_CLIENTPOC} from "@common/gql/client";
import AddPoc from "@common/POC/AddPOC";
import {ADD_UMBRELLAPOC, UPDATE_UMBRELLAPOC} from "@common/gql/umbrella";

const ViewUmbrella = (props: { data: any, refetch:any }) => {
    const [open, setOpen] = React.useState(false);

    const onClose = () => {
        setOpen(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    return (
        <>
            <Button type="link" onClick={showDrawer}>
                <EyeOutlined/>
            </Button>
            <Drawer
                title="View Umbrella"
                onClose={onClose}
                open={open}
                size={'large'}
            >
                <Descriptions
                    column={1}
                    layout={"vertical"}
                    items={[
                        {
                            label: "Company Name",
                            children: props.data.companyName,
                        },
                        {
                            label: "POC",
                            children: <div className={'gap-5 grid grid-cols-1'}>
                                <AddPoc clientId={props.data.id} refetch={props.refetch} mutation={ADD_UMBRELLAPOC} mutationKey={'addUmbrellaPOC'}/>
                                {props.data.poc.map((item: any) => {
                                    return <POC data={item} key={item.id} refetch={props.refetch} mutation={UPDATE_UMBRELLAPOC} mutationKey={'updateUmbrellaPOC'}/>
                                })}
                            </div>,
                        }
                    ]}
                />
            </Drawer>
        </>
    );
};

export default ViewUmbrella;