export const Config = {
    currency: [],
    country: [],
};

const tokenData = {
    accessToken: "",
};

export type tokenType = typeof tokenData;

export const userState: {
    tokens?: tokenType,
    role: string
} = {
    tokens: undefined,
    role: ""

};

export const PreloadedState = {
    config: Config,
    user: userState
}

export type stateType = typeof PreloadedState;