import React from 'react';
import {Form, Select} from "antd";
import {useLazyQuery} from "@apollo/client";
import {useDebounceValue} from "usehooks-ts";
import {SEARCH_CLIENT} from "@common/gql/client";

const ClientField = (props: { defaultValue?: any, form: any }) => {
    const [searchClient, {loading, data}] = useLazyQuery(SEARCH_CLIENT);
    const [name, setName] = useDebounceValue('', 500)
    const [refresh, setRefresh] = React.useState(false)

    React.useEffect(() => {
        if (name.length >= 3)
            searchClient({
                variables: {name: name}
            })
    }, [name, searchClient])

    const defaultOptions = React.useMemo(() => {
        if (props.defaultValue.client) {
            setTimeout(()=>{
                setRefresh(!refresh)
            },100)
            return [
                {
                    key: props.defaultValue.client.id,
                    value: props.defaultValue.client.id,
                    label: props.defaultValue.client.companyName,
                    title: props.defaultValue.client.companyName
                }
            ]
        }
        return []
    }, [props.defaultValue.client])

    const defaultPOCOptions = React.useMemo(() => {
        if (props.defaultValue.client) {
            setTimeout(()=>{
                setRefresh(!refresh)
            },100)
            return props.defaultValue.client.poc.map((item:any)=>({
                key: item.id,
                value: item.id,
                label: item.name,
                title: item.name
            }))
        }
        return []
    }, [props.defaultValue.clientPOC])

    const options = React.useMemo(() => {
        return data?.searchClient?.map((item: any) => ({
            key: item.id,
            value: item.id,
            label: item.companyName,
            title: item.companyName
        })) || []
    }, [data?.searchClient])

    const selectedClient = props.form.getFieldValue("client")

    const pocOptions = selectedClient && data?.searchClient ? data?.searchClient.find((item:any)=>item.id === selectedClient).poc.map((item:any)=>({
        key: item.id,
        value: item.id,
        label: item.name,
        title: item.name
    })) : defaultPOCOptions

    return (
        <>
            <Form.Item label={'Client'} name={'client'} required rules={[{required: true}]}>
                <Select
                    showSearch
                    filterOption={false}
                    placeholder={"Search Client Name"}
                    onSearch={setName}
                    options={options.length ? options : defaultOptions}
                    loading={loading}
                    onChange={() => setRefresh(!refresh)}
                />
            </Form.Item>
            {
                selectedClient ?
                    <Form.Item label={'Client POC'} name={'clientPOC'} required rules={[{required: true}]}>
                        <Select
                            filterOption={false}
                            placeholder={"Search Client POC"}
                            options={pocOptions}
                        />
                    </Form.Item>
                    : null
            }
        </>
    );
};

export default ClientField;