import React from 'react';
import {Button, Form, Input, Modal, notification} from "antd";
import {useMutation} from "@apollo/client";
import {ClientInput} from "../../gql/graphql";
import {EditOutlined} from "@ant-design/icons";
import {UPDATE_CLIENT} from "@common/gql/client";

const EditClient = (props: { refetch: any, clientId: string, companyName: string }) => {
    const [api, contextHolder] = notification.useNotification();
    const [open, setOpen] = React.useState(false);
    const formRef = React.useRef<any>(null);
    const [updateClient, {loading, error}] = useMutation(UPDATE_CLIENT);
    const showDrawer = () => {
        setOpen(true);
        setTimeout(()=>{
            formRef.current?.setFieldsValue({companyName: props.companyName})
        },100)
    };

    const onClose = () => {
        setOpen(false);
    };

    const onSubmit = (data: ClientInput) => {
        updateClient({
            variables: {
                data: data.companyName,
                id: props.clientId
            }
        }).then((resp) => {
            if (resp.data.updateClient) {
                setOpen(false)
                formRef.current?.resetFields();
                props.refetch();
            }
        }).catch(() => {
        })
    }

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors.length) {
                api.error({
                    message: 'Server Error',
                    description: error.graphQLErrors[0].message
                })
            } else if (error.clientErrors.length) {
                api.error({
                    message: 'Client Error',
                    description: error.clientErrors[0].message
                })
            }
        }
    }, [error])

    const reset = () => {
        formRef.current?.setFieldsValue({companyName: props.companyName})
    }

    return (
        <>
            {contextHolder}
            <Button type="link" onClick={showDrawer}>
                <EditOutlined/>
            </Button>
            <Modal
                title="Edit Client"
                onClose={onClose}
                onCancel={onClose}
                open={open}
                footer={null}
            >
                <Form onFinish={onSubmit} ref={formRef}>
                    <Form.Item name={"companyName"} label={"Company Name"} required rules={[{required: true}]}>
                        <Input placeholder={"Client Name"}/>
                    </Form.Item>
                    <div className={'w-full flex justify-end gap-5'}>
                        <Button type={'primary'} loading={loading} htmlType={'submit'}>
                            Save
                        </Button>
                        <Button htmlType={'button'} onClick={reset}
                                loading={loading}>Reset</Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default EditClient;