import {createSlice} from "@reduxjs/toolkit";
import {Config} from "../initialState";


const configSlice = createSlice({
    name: "config",
    initialState: Config,
    reducers: {
        setConfig: (state, action) => {
            state.currency = action.payload.currency;
            state.country = action.payload.country;
        }
    }
});

export const {
    setConfig,
} = configSlice.actions;
export default configSlice.reducer;
