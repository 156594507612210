import {gql} from "@apollo/client";

export const CREATE_CLIENT = gql`
    mutation CreateClient($data: ClientInput!) {
        addClient(data: $data)
    }
`

export const UPDATE_CLIENT = gql`
    mutation UpdateClient($data: String!, $id: String) {
        updateClient(companyName: $data, id: $id)
    }
`

export const GET_CLIENTS = gql`
    query QueryClient($pageNum: Int, $pageSize: Int) {
        clients(pageNum: $pageNum, pageSize: $pageSize) {
            results {
                id
                companyName
                poc {
                    id
                    name
                    email
                    phone
                }
            }
            total
        }
    }
`

export const SEARCH_CLIENT = gql`
    query SearchClient($name: String) {
        searchClient(name: $name){
            id
            companyName
            poc {
                id
                name
            }
        }
    }
`

export const UPDATE_CLIENTPOC = gql`
    mutation UpdateClientPOC($data: ClientPersonInput, $id: String){
        updateClientPOC(data: $data, id: $id)
    }
`

export const ADD_CLIENTPOC = gql`
    mutation AddClientPOC($data: ClientPersonInput, $id: String){
        addClientPOC(data: $data, id: $id)
    }
`