import React from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {Button, Card, Descriptions, notification, Steps} from "antd";
import {GET_CANDIDATE, UPDATE_CANDIDATE_STATUS} from "@common/gql/candidate";
import {getNextStatus} from "@config/nextStatus";

const ViewCandidate = () => {
    const [api, contextHolder] = notification.useNotification();
    const params = useParams()

    const {loading, data, refetch} = useQuery(GET_CANDIDATE, {
        variables: {id: params.id},
    });

    const [updateStatus, {loading: statusLoading, error}] = useMutation(UPDATE_CANDIDATE_STATUS)

    const updateCanStatus = () => {
        updateStatus({
            variables: {id: params.id},
        }).then((resp) => {
            if (resp.data.updateStatus) {
                refetch();
            }
        })
    }

    const items = React.useMemo(() => {
        if (data?.candidate)
            return {
                personal: [
                    {
                        key: 'name',
                        label: 'Name',
                        children: data.candidate['name']
                    },
                    {
                        key: 'personalEmail',
                        label: 'Personal Email',
                        children: data.candidate['personalEmail']
                    },
                    {
                        key: 'contact',
                        label: 'Phone Number',
                        children: data.candidate['contact']
                    },
                    {
                        key: 'country',
                        label: 'Country',
                        children: data.candidate['country']
                    },
                    {
                        key: 'currency',
                        label: 'Currency',
                        children: data.candidate['currency']
                    },
                    {
                        key: 'accountType',
                        label: 'Account Type',
                        children: data.candidate['accountType']
                    },
                    {
                        key: 'rate',
                        label: 'Rate',
                        children: `$${data.candidate.candidateRate['rate']} ${data.candidate.perDay ? " / Day" : " / Hr"}`
                    },
                    {
                        key: 'otRate',
                        label: 'OT Rate',
                        children: `$${data.candidate.candidateRate['otRate']} / Hr`
                    },
                ],
                clientInfo: [
                    {
                        key: 'name',
                        label: 'Client Name',
                        children: data.candidate.client['companyName']
                    },
                    {
                        key: 'pocName',
                        label: 'POC Name',
                        children: data.candidate.clientPOC['name']
                    },
                    {
                        key: 'pocEmail',
                        label: 'POC Email',
                        children: data.candidate.clientPOC['email']
                    },
                    {
                        key: 'pocPhone',
                        label: 'POC Phone',
                        children: data.candidate.clientPOC['phone']
                    },
                    {
                        key: 'rate',
                        label: 'Rate',
                        children: `$${data.candidate.clientRate['rate']} ${data.candidate.perDay ? " / Day" : " / Hr"}`
                    },
                    {
                        key: 'otRate',
                        label: 'OT Rate',
                        children: `$${data.candidate.clientRate['otRate']} / Hr`
                    }
                ],
                umbrellaInfo: [
                    {
                        key: 'name',
                        label: 'Umbrella Name',
                        children: data.candidate.client['companyName']
                    },
                    {
                        key: 'pocName',
                        label: 'POC Name',
                        children: data.candidate.clientPOC['name']
                    },
                    {
                        key: 'pocEmail',
                        label: 'POC Email',
                        children: data.candidate.clientPOC['email']
                    },
                    {
                        key: 'pocPhone',
                        label: 'POC Phone',
                        children: data.candidate.clientPOC['phone']
                    }
                ]
            }
        else
            return {}
    }, [data?.candidate])

    const steps = [
        {
            key: 'Selected',
            title: 'Selected',
        },
        {
            key: 'BGV',
            title: 'BGV',
        },
        {
            key: 'Onboarded',
            title: 'Onboarded',
        }
    ]

    React.useEffect(() => {
        if (error) {
            if (error.graphQLErrors.length) {
                api.error({
                    message: 'Server Error',
                    description: error.graphQLErrors[0].message
                })
            } else if (error.clientErrors.length) {
                api.error({
                    message: 'Client Error',
                    description: error.clientErrors[0].message
                })
            }
        }
    }, [error])

    if (loading)
        return <></>
    return (
        <div>
            {contextHolder}
            <div className={'flex justify-between items-center mb-4'}>
                <span className={'text-2xl font-semibold'}>Candidate Info</span>
            </div>
            <Steps current={data?.candidate.stage === "Selected" ? 0 : data?.candidate.stage === "BGV" ? 1 : 2}
                   items={steps}/>
            <div className={"flex justify-between items-center mt-5"}>
                {
                    data?.candidate.status ?
                        <div><span
                            className={"font-bold"}>Status: </span>{data?.candidate.status}</div>
                        :
                        <Button>Request Illustration</Button>
                }
                {
                    data?.candidate.status && data?.candidate.status !== "Onboarded" ?
                        <Button type={"primary"} loading={statusLoading}
                                onClick={updateCanStatus}>{getNextStatus(data?.candidate.status)?.text}</Button>
                        : null
                }
            </div>
            <div className={'flex gap-5 my-5'}>
                <Card title="Candidate Info" type={'inner'} className={"flex-[2]"}>
                    <Descriptions items={items.personal} layout={'vertical'} column={4}/>
                </Card>

            </div>
            <div className={'flex gap-5 my-5'}>
                <Card title="Client Info" type={'inner'} className={"flex-[3]"}>
                    <Descriptions items={items.clientInfo} layout={'vertical'} column={4}/>
                </Card>
                <Card title="Umbrella Info" type={'inner'} className={"flex-[3]"}>
                    <Descriptions items={items.umbrellaInfo} layout={'vertical'} column={4}/>
                </Card>
            </div>

        </div>
    );
};

export default ViewCandidate;