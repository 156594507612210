import {Status} from "../gql/graphql";

export const getNextStatus = (status: any) => {
    switch (status) {
        case Status.RequestedIllustration:
            return {
                text: "Send Illustration",
            }
        case Status.IllustrationSent:
            return {
                text: "Request Documents"
            }
        case Status.RequestedDocuments:
            return {
                text: "Send Documents",
            }
        case Status.DocumentSent:
            return {
                text: "Mark BGV Started",
            }
        case Status.BgvStarted:
            return {
                text: "Mark BGV Complete"
            }
        case Status.BgvCompleted:
            return {
                text: "Mark Onboarded"
            }
    }
}


/*
bill of umbrella

fields: bill from
    - bill date
    - due date
    - refrence
    - description (name (month'YY))
    - quantity : number of days
    - unit price : per day rate
    ** hourly rate for OT
    - account: wages payroll, subcontractor
    - tax rate
    - accounts are

invoice to client

 */