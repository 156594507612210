import {gql} from "@apollo/client";

export const CREATE_UMBRELLA = gql`
    mutation CreateUmbrella($data: UmbrellaInput!) {
        addUmbrella(data: $data)
    }
`

export const UPDATE_UMBRELLA = gql`
    mutation UpdateUmbrella($data: String!, $id: String) {
        updateUmbrella(companyName: $data, id: $id)
    }
`

export const GET_UMBRELLAS = gql`
    query QueryUmbrella($pageNum: Int, $pageSize: Int) {
        umbrellas(pageNum: $pageNum, pageSize: $pageSize) {
            results {
                id
                companyName
                poc {
                    name
                    email
                    phone
                }
            }
            total
        }
    }
`

export const SEARCH_UMBRELLA = gql`
    query SearchUmbrella($name: String) {
        searchUmbrella(name: $name){
            id
            companyName
            poc {
                id
                name
            }
        }
    }
`

export const UPDATE_UMBRELLAPOC = gql`
    mutation UpdateClientPOC($data: UmbrellaPersonInput, $id: String){
        updateUmbrellaPOC(data: $data, id: $id)
    }
`

export const ADD_UMBRELLAPOC = gql`
    mutation AddClientPOC($data: UmbrellaPersonInput, $id: String){
        addUmbrellaPOC(data: $data, id: $id)
    }
`