import React from 'react';
import {Card, Descriptions} from "antd";
import EditPoc from "@common/POC/EditPOC";
import {DocumentNode} from "@apollo/client";

const Index = (props: { data: any, refetch: any, mutation: DocumentNode, mutationKey: string }) => {
    return (
        <Card
            title={props.data.name}
            extra={<>
                <EditPoc data={props.data} pocId={props.data.id} refetch={props.refetch} mutation={props.mutation} mutationKey={props.mutationKey}/>
            </>}
        >
            <Descriptions
                items={[
                    {
                        label: "Email",
                        children: props.data.email,
                    },
                    {
                        label: "Phone",
                        children: props.data.phone,
                    }
                ]}
            />
        </Card>
    );
};

export default Index;