import React from 'react';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import Dashboard from "./Dashboard";
import {connect} from "react-redux";
import {stateType} from "store/initialState";
import Login from "./Login";
import Header from "./Header";
import Candidates from "./Candidates";
import ViewCandidate from './Candidates/ViewCandidate';
import Users from "./Users";
import Client from "./Client";
import Umbrella from "./Umbrella";
import {gql, useLazyQuery} from "@apollo/client";
import {setConfig} from "@store/reducers/configSlice";

const GET_CONFIG = gql`
    query GetConfig {
        config{
            country
            currency
        }
    }
`

const Index = (props: { token: any, role: string, setConfig: any }) => {
    const [getConfig, {data}] = useLazyQuery(GET_CONFIG);
    React.useEffect(() => {
        if (props.token) {
            getConfig()
        }
    }, [props.token])

    React.useEffect(() => {
        if (data)
            props.setConfig({
                currency: data.config.currency,
                country: data.config.country,
            })
    }, [data])

    const items = [
        {
            key: 'dashboard',
            label: `Dashboard`,
            path: '/',
            element: <Dashboard/>,
        },
        {
            key: 'umbrella',
            label: `Umbrella`,
            path: '/umbrella',
            element: <Umbrella/>,
        },
        {
            key: 'client',
            label: `Client`,
            path: '/client',
            element: <Client/>,
        },
        {
            key: 'candidates',
            label: `Candidates`,
            path: '/candidates',
            element: <Candidates/>,
        }
    ];

    const navigationItems = [
        {
            path: 'candidates/:id',
            element: <ViewCandidate/>,
        }
    ]
    if (props.role === 'Admin')
        items.push({
            key: 'users',
            label: `Users`,
            path: '/users',
            element: <Users/>,
        })

    const router = createBrowserRouter(props.token ? [
                {
                    path: '',
                    element: <Header items={items}/>,
                    children: [
                        ...items.map((item) => ({
                            path: item.path,
                            element: item.element,
                        })),
                        ...navigationItems,
                        {
                            path: '*',
                            element: <Navigate to="/" replace/>,
                        }
                    ]
                }
            ]
            :
            [
                {
                    path: "/",
                    element: <Login/>,
                }, {
                path: '*',
                element: <Navigate to="/" replace/>,
            }
            ]
    );

    return (
        <RouterProvider
            router={router}
        />
    );
};

const mapStateToProps = (state: stateType) => {
    return {
        token: state.user.tokens,
        role: state.user.role,
    }
}

export default connect(mapStateToProps, {setConfig})(Index);