import React from 'react';
import {Table} from "antd";
import {useQuery} from "@apollo/client";
import CreateClient from "./CreateClient";
import EditClient from "./EditClient";
import {GET_CLIENTS} from "@common/gql/client";
import ViewClient from "./ViewClient";

const Index = () => {
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const {loading, error, data, refetch} = useQuery(GET_CLIENTS, {
        variables: {
            pageNum: page,
            pageSize: pageSize
        }
    });

    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'companyName',
            key: 'companyName',
        }, {
            title: 'POC',
            dataIndex: 'poc',
            key: 'poc',
            render: (poc: any) => (poc.length)
        }, {
            title: 'Actions',
            dataIndex: 'id',
            key: 'id',
            render: (text: any, record: any) => {
                return <>
                    <ViewClient data={record} refetch={refetch}/>
                    <EditClient clientId={text} refetch={refetch} companyName={record.companyName}/>
                </>
            }
        }
    ]
    return <Table
        title={() => {
            return <div className={'flex justify-between items-center'}>
                <span className={'text-xl font-semibold'}>Clients</span>
                <CreateClient refetch={refetch}/>
            </div>
        }}
        columns={columns}
        dataSource={error ? [] : data?.clients.results}
        loading={loading}
        onChange={(page) => {
            setPage(page.current ?? 1)
            setPageSize(page.pageSize ?? 10)
        }}
        pagination={{
            current: page,
            pageSize: pageSize,
            total: data?.clients.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        }}
    />;
};

export default Index;